import * as React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer">
        <a href="/colophon">Colophon</a>
      </div>
    </React.Fragment>
  );
};

export default Footer;
