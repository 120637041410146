import * as React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Metadata from "../components/Metadata";

const IndexPage = () => {

return(
    <div className="container">

    <Metadata title="Colophon" description="Colophon" />

    <Header />

      
    <article>

        <h3 className="main">Colophon</h3>
    
        <p>
           Statically Generated using <a href="https://www.gatsbyjs.com/">Gatsby.</a>
        </p>

        <p>
            Hosted by the Gatsby Cloud.
        </p>

        <p>
            DNS maintianed by <a href="https://in.godaddy.com/">GoDaddy</a>
        </p>

        <p>
            Licensed under <a href="https://creativecommons.org/licenses/by/2.5/in/">CC Attibution 2.5 Licence</a>
        </p>

        
    </article>

    <Footer />
  </div>
  )
}

export default IndexPage
